<template>
  <v-form ref="form" v-model="valid" lazy-validation class="campaign-form">
    <ShowBanner
      :dialog="bannerShow"
      :banner="preview"
      @dismiss="dismissFn($event)"
    />
    <v-row>
      <v-col cols="8">
        <v-card flat class="mb-5">
          <v-card-title class="py-2">
            <h5>Banner Details</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row dense class="flex-column">
              <v-col>
                <h5 class="mb-1">Campaign</h5>
                <v-combobox
                  :items="all_campaigns"
                  label="Type to select campaign"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'Select campaign to continue!']"
                  v-model="campaign"
                  @change="getCampaign()"
                  hide-details="auto"
                  single-line
                ></v-combobox>
              </v-col>
              <v-col>
                <h5 class="mb-1">Banner Name</h5>
                <v-text-field
                  dense
                  v-model="banner_name"
                  :rules="[(v) => !!v || 'Enter banner name to continue!']"
                  outlined
                  hide-details="auto"
                  label="Enter banner name"
                  placeholder="eg. campaign-name 300x250"
                  persistent-placeholder
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="my-5"></v-divider>

            <v-row dense class="flex-column">
              <v-col>
                <h5 class="mb-1">Run campaign on App/Site</h5>
                <div class="d-flex flex-column pb-3 pt-1">
                  <v-btn-toggle
                    color="primary"
                    dense
                    v-model="app_site"
                    divided
                    variant="outlined"
                  >
                    <v-btn>App</v-btn>
                    <v-btn>Site</v-btn>
                    <v-btn>Both</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>

              <v-col>
                <h5 class="mb-1">Banner Type</h5>
                <v-btn-toggle
                  v-model="banner_type"
                  color="primary"
                  variant="outlined"
                >
                  <v-btn>
                    <v-icon left> mdi-xml </v-icon>
                    HTML
                  </v-btn>

                  <v-btn>
                    <v-icon left> mdi-video </v-icon>
                    Video
                  </v-btn>

                  <v-btn>
                    <v-icon left> mdi-image </v-icon>
                    Native
                  </v-btn>

                  <v-btn>
                    <v-icon left> mdi-video-image </v-icon>
                      Hybrid Video
                  </v-btn>

                  <!-- <v-btn>
                    <v-icon left>
                      mdi-music
                    </v-icon>
                    Audio
                  </v-btn> -->
                </v-btn-toggle>
              </v-col>

              <!-- Video Banner -->
              <!-- <v-col v-if="__selectedBannerType == 'video'">
                <h5 class="mb-1">Video Upload</h5>
                <v-file-input
                  v-model="banner_file"
                  label="Video File"
                  outlined
                  dense
                  show-size
                  @change="handleFileChange()"
                  accept="video/*"
                  hide-details=""
                  prepend-icon="mdi-video"
                ></v-file-input>
              </v-col> -->
            </v-row>

            <!-- campaign start and end date -->
            <v-row dense>
              <v-col>
                <h5 class="mb-1">Start Date</h5>
                <transition name="slide-fade">
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="interval_start"
                            label="Start Date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            single-line
                            placeholder="Pick start date"
                            persistent-placeholder
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="interval_start"
                          @input="menu1 = false"
                          locale="en-us"
                          :min="minDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </transition>
              </v-col>

              <v-col>
                <h5 class="mb-1">End Date</h5>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="interval_end"
                      label="End date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      placeholder="Pick end date"
                      persistent-placeholder
                      hide-details="auto"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="interval_end"
                    @input="menu2 = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <v-row  v-if="__selectedBannerType == 'html' || __selectedBannerType == 'video_banner'">
              <v-col cols="12">
                <h4 class="mb-2">Choose Ad placement positions</h4>

                <div fluid>
                  <v-checkbox
                    v-model="selectAllPositions"
                    label="Select all positions"
                  ></v-checkbox>
                </div>

                <v-chip-group  v-if="__selectedBannerType == 'html' || __selectedBannerType == 'video_banner'" v-model="positions" column multiple>
                  <v-chip filter outlined> Unknown </v-chip>
                  <v-chip filter outlined> Above The Fold </v-chip>
                  <v-chip filter outlined>
                    Locked (i.e., fixed position)
                  </v-chip>
                  <v-chip filter outlined> Below The Fold </v-chip>
                  <v-chip filter outlined> Header </v-chip>
                  <v-chip filter outlined> Footer </v-chip>
                  <v-chip filter outlined> Sidebar </v-chip>
                  <v-chip filter outlined> Fullscreen </v-chip>
                </v-chip-group>

              </v-col>
            </v-row>

            <v-divider
              v-if="__selectedBannerType == 'native'"
              class="my-5"
            ></v-divider>

            <v-row v-if="__selectedBannerType == 'native'" dense>
              <!-- Native Banner -->
              <v-col>
                <h5 class="mb-1">Image Url *</h5>
                <v-text-field
                  outlined
                  dense
                  v-model="imageurl"
                  label="Image Url"
                  hide-details=""
                  placeholder="e.g. https://sample.ad.server/ads/campaign-name/300x600"
                  single-line
                  class="mb-3"
                  persistent-placeholder
                ></v-text-field>

                <div class="py-4">
                  <div class="d-flex align-center">
                    <v-text-field
                      class="mr-2"
                      outlined
                      dense
                      v-model="width"
                      label="Image Width"
                      type="number"
                      hide-details="auto"
                      placeholder="0px"
                      persistent-placeholder
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      v-model="height"
                      label="Image Height"
                      type="number"
                      hide-details="auto"
                      placeholder="0px"
                      persistent-placeholder
                    ></v-text-field>
                  </div>
                </div>

                <h5 class="mb-1">Title *</h5>
                <v-text-field
                  outlined
                  dense
                  v-model="title"
                  label="Title"
                  hide-details=""
                  placeholder="e.g. Coke with Meals"
                  single-line
                  class="mb-3"
                  persistent-placeholder
                ></v-text-field>

                <h5 class="mb-1">Description *</h5>
                <v-text-field
                  outlined
                  dense
                  v-model="description"
                  label="Description"
                  hide-details=""
                  placeholder="e.g. Enjoy a cold coke with your meals"
                  single-line
                  class="mb-3"
                  persistent-placeholder
                ></v-text-field>

                <h5 class="mb-1">Call to Action *</h5>
                <v-text-field
                  outlined
                  dense
                  v-model="call_to_action"
                  label="Call to Action"
                  hide-details=""
                  placeholder="e.g. Order Now"
                  single-line
                  class="mb-5"
                  persistent-placeholder
                ></v-text-field>

                <v-card style="border: 1px solid orangered !important" outlined>
                  <v-card-text>
                    <h5 class="mb-1">Brand (optional)</h5>
                    <v-text-field
                      outlined
                      dense
                      v-model="brand"
                      label="Brand name"
                      hide-details=""
                      placeholder="e.g. Coca Cola"
                      single-line
                      class="mb-3"
                      persistent-placeholder
                    ></v-text-field>

                    <h5 class="mb-1">Brand Logo Url</h5>
                    <v-text-field
                      outlined
                      dense
                      v-model="logo_imageurl"
                      label="Logo Url"
                      hide-details=""
                      placeholder="e.g. https://sample.ad.server/ads/campaign-name/logo"
                      single-line
                      class="mb-5"
                      persistent-placeholder
                    ></v-text-field>

                    <div class="d-flex align-center">
                      <v-text-field
                        class="mr-2"
                        outlined
                        dense
                        v-model="logo_width"
                        label="Logo Width"
                        type="number"
                        hide-details="auto"
                        placeholder="0px"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        outlined
                        dense
                        v-model="logo_height"
                        label="Logo Height"
                        type="number"
                        hide-details="auto"
                        placeholder="0px"
                        persistent-placeholder
                      ></v-text-field>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- <v-file-input
                  v-if="dimensions"
                  prepend-icon="mdi-camera"
                  v-model="banner_file"
                  show-size
                  accept="image/*"
                  label="Image File"
                  @change="handleFileChange()"
                  hide-details=""
                  outlined
                  dense
                ></v-file-input> -->
              </v-col>
            </v-row>

            <v-row
              class="py-3"
              v-if="
                __selectedBannerType == 'video' ||
                __selectedBannerType == 'native'
              "
              dense
            >
              <!-- Native / Video Banner -->
              <v-col>
                <h5 class="mb-1">Video Url *</h5>
                <v-text-field
                  outlined
                  dense
                  v-model="video_url"
                  label="Video Url"
                  hide-details=""
                  placeholder="e.g. https://sample.ad.server/ads/campaign-name/300x600"
                  single-line
                  class="mb-5"
                  persistent-placeholder
                ></v-text-field>

                <div>
                  <div class="d-flex align-center">
                    <v-text-field
                      class="mr-2"
                      outlined
                      dense
                      v-model="video_width"
                      label="Video Width"
                      type="number"
                      hide-details="auto"
                      placeholder="0px"
                      persistent-placeholder
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      v-model="video_height"
                      label="Video Height"
                      type="number"
                      hide-details="auto"
                      placeholder="0px"
                      persistent-placeholder
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <v-row v-if="__selectedBannerType == 'html' || __selectedBannerType == 'video_banner'" dense>
              <v-col cols="12">
                <h5 class="mb-1">Match Size</h5>
              </v-col>
              <v-col cols="6">
                <div class="d-flex flex-column pb-3">
                  <v-btn-toggle
                    color="primary"
                    dense
                    v-model="match_size"
                    divided
                    variant="outlined"
                  >
                    <v-btn>Any</v-btn>
                    <v-btn>Specified</v-btn>
                    <v-btn>W/H Ranges</v-btn>
                    <v-btn>W/H List</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>

              <v-col cols="12">
                <h5 class="mb-2">Banner Dimensions</h5>
                <v-card outlined>
                  <v-card-text v-if="__selectedMatchSize === 'specified'">
                    <div class="d-flex align-center">
                      <v-text-field
                        class="mr-2"
                        outlined
                        dense
                        v-model="banner_width"
                        label="Width"
                        type="number"
                        hide-details="auto"
                        placeholder="0px"
                        persistent-placeholder
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        v-model="banner_height"
                        label="Height"
                        type="number"
                        hide-details="auto"
                        placeholder="0px"
                        persistent-placeholder
                      ></v-text-field>
                    </div>
                  </v-card-text>

                  <v-card-text v-if="__selectedMatchSize === 'ranges'">
                    <div class="d-flex align-center">
                      <v-text-field
                        class="mr-2"
                        outlined
                        dense
                        v-model="banner_width_range"
                        label="Width Range"
                        type="text"
                        hide-details="auto"
                        placeholder="n-n"
                        persistent-placeholder
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        v-model="banner_height_range"
                        label="Height Range"
                        type="text"
                        hide-details="auto"
                        placeholder="n-n"
                        persistent-placeholder
                      ></v-text-field>
                    </div>
                  </v-card-text>

                  <v-card-text v-if="__selectedMatchSize === 'list'">
                    <v-textarea
                      v-model="whlist"
                      outlined
                      rows="2"
                      label="List of W/H"
                      placeholder="W*H"
                      persistent-placeholder
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- HTML Banner -->
              <v-col v-if="__selectedBannerType == 'html' || __selectedBannerType == 'video_banner'">
                <v-row dense align="end">
                  <v-col>
                    <h5 class="mb-1">Banner URL Link</h5>
                    <v-text-field
                      outlined
                      dense
                      v-model="creative_url_link"
                      label="Creative URL Link"
                      hide-details=""
                      placeholder="e.g. https://sample.ad.server/ads/campaign-name/300x600"
                      single-line
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col class="shrink"
                    ><v-btn
                      :disabled="isDisabled()"
                      @click="showBanner()"
                      depressed
                      color="secondary"
                    >
                      Preview
                    </v-btn></v-col
                  >
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="my-5"></v-divider>

            <v-row dense class="flex-column">
              <v-col
                ><v-alert
                  dense
                  text
                  color="teal"
                  icon="mdi-information-outline"
                  type="info"
                  class="mb-2"
                  border="left"
                >
                  {{ cascade_status }}
                </v-alert></v-col
              >
              <v-col>
                <h5>Banner Click Effect</h5>
                <v-radio-group
                  dense
                  row
                  v-model="banner_click_effect"
                  class="mt-0"
                  hide-details
                >
                  <v-radio value="clickable">
                    <template v-slot:label>
                      <span class="text-caption">Clickable</span>
                    </template>
                  </v-radio>
                  <v-radio value="interactive">
                    <template v-slot:label>
                      <span class="text-caption">Interactive</span>
                    </template>
                  </v-radio>
                  <v-radio value="video-display">
                    <template v-slot:label>
                      <span class="text-caption">Video Display</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <h5 class="mb-1">Click Through URL</h5>
                <!-- <p class="text-caption mb-2">
                  The entire banner will be clickable, and will redirect to the
                  specified click through URL
                </p> -->
                <v-text-field
                  outlined
                  dense
                  label="Click Throug URL"
                  v-model="click_through_url"
                  :disabled="disabled"
                  single-line
                  placeholder="e.g. https://example.domain?product=123"
                  persistent-placeholder
                  hint=" The entire banner will be clickable, and will redirect to the
                  specified click through URL"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col>
                <v-checkbox
                  v-model="interstitial_status"
                  label="Make Banner Interstitial"
                ></v-checkbox>
              </v-col>

              <v-divider class="my-2"></v-divider>
              <v-col>
                <h5 class="mb-1">Restricted Categories</h5>
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="restricted_categories"
                  item-text="name"
                  return-object
                  outlined
                  label="Select restricted category"
                  dense
                  v-model="selected_restricted_categories"
                  single-line
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="mb-5"
          flat
          v-if="superAdmin"
          style="border: 1px solid orange !important"
        >
          <v-card-title class="py-2">
            <h5>Banner Review</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <h5 class="mb-1">Bid eCPM</h5>
                <v-text-field
                  dense
                  outlined
                  v-model="eCpm"
                  label="Bid eCPM value"
                  hide-details="auto"
                  single-line
                  persistent-placeholder
                  placeholder="0.0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <h5>Add Banner Branding</h5>
                <v-radio-group
                  class="mt-0"
                  v-model="add_banner_branding"
                  dense
                  row
                  hide-details
                >
                  <v-radio :value="true" @change="campaignBannerType()">
                    <template v-slot:label>
                      <span class="text-caption">Yes</span>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <span class="text-caption">No</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col v-if="add_banner_branding">
                <h5>Banner Branding Type</h5>
                <v-radio-group
                  v-model="banner_branding_type"
                  class="mt-0"
                  dense
                  row
                  hide-details
                >
                  <v-radio value="pal-orange">
                    <template v-slot:label>
                      <div>
                        <v-img
                          :src="require('../../assets/pal-orange.svg')"
                          max-width="20"
                        ></v-img>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="pal-blue">
                    <template v-slot:label>
                      <div>
                        <v-img
                          :src="require('../../assets/pal-blue.svg')"
                          max-width="20"
                        ></v-img>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="mediapal">
                    <template v-slot:label>
                      <div>
                        <v-img
                          :src="require('../../assets/mediapal-blue.svg')"
                          max-width="50"
                        ></v-img>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="adchoices">
                    <template v-slot:label>
                      <div>
                        <v-img
                          :src="require('../../assets/adchoices.png')"
                          max-width="20"
                        ></v-img>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-5" flat>
          <v-card-title class="py-2">
            <h5>Banner Targeting Options</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <banner-targeting-options
              :rules="rules"
              :campaign="campaign"
              :banner="banner"
              @emitBannerTargetingOptions="setBannerTargetingOptions($event)"
              @emitBannerBudget="setBannerBudget($event)"
              @emitDailyBudget="setDailyBudget($event)"
              @emitTotalBudget="setTotalBudget($event)"
              @emitFrequencySpec="setFrequencySpec($event)"
              @emitFrequencyExpireAfter="setFrequencyExpireAfter($event)"
              @emitFrequencyCount="setFrequencyCount($event)"
            />
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn block @click="validate" depressed color="primary"
              >Save Banner
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat>
          <v-card-title class="py-2">
            <h5>Creative Preview</h5>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <iframe
              v-if="creative_url_link && __selectedBannerType == 'html' || __selectedBannerType == 'video_banner'"
              :src="creative_url_link"
              frameborder="0"
              :height="banner_height"
              class="mx-auto banner_iframe"
            ></iframe>

            <div v-else-if="imageurl && __selectedBannerType == 'native'">
              <v-img
                :src="imageurl"
                :width="width"
                :height="height"
                class="mx-auto"
              ></v-img>
            </div>

            <div v-else-if="video_url && __selectedBannerType == 'video'">
              <video :width="video_width" :height="video_height" controls>
                <source :src="video_url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <v-skeleton-loader
              v-else
              class="mx-auto"
              type="card"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BannerTargetingOptions from "./BannerTargetingOptions.vue"
import ShowBanner from "../Programmatic/ShowBanner.vue"
// import { serialize } from "object-to-formdata"

export default {
  props: {
    all_campaigns: {
      Object,
    },
    restricted_categories: {
      Array,
    },
    rules: {
      Array,
    },
    banner: {
      Object,
    },
    fetched_campaign: {
      Object,
    },
  },
  data: () => ({
    bannerShow: false,
    preview: {},
    valid: true,
    app_site: 2,
    match_size: 1,
    disabled: false,
    interstitial_status: "",
    campaign: "",
    campaigns: [],
    campaign_id: "",
    banner_name: "",
    add_banner_branding: true,
    banner_type: 0,
    banner_file: [],
    cascade_status: "Banner uses a custom landing page",
    banner_height: "",
    banner_width: "",
    banner_width_range: "",
    banner_height_range: "",
    whlist: "",
    creative_url_link: "",
    interval_start: "",
    interval_end: "",
    banner_branding_type: "",
    banner_click_effect: "clickable",
    click_through_url: "",
    eCpm: "",
    selectAllPositions: false,
    positions: [],
    frequency_spec: "",
    frequency_expire_after: "",
    frequency_count: "",
    selected_banner_targeting_options: [],
    banner_budget: "",
    daily_budget: "",
    total_budget: "",
    banner_data: [],
    selected_campaign: {},
    selected_restricted_categories: null,
    restricted_category: "",
    minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    banner_types: [
      {
        type: "html",
        name: "Generic HTML5 Banner",
      },
      {
        type: "video",
        name: "Video Banner",
      },
      {
        type: "image",
        name: "Local Banner Image",
      },
    ],
    image: {
      width: "",
      height: "",
    },
    dimensions: "",
    valid_dimensions: [
      "300x250",
      "320x480",
      "336x280",
      "344x",
      "360x",
      "384x",
      "393x",
      "412x",
      "414x",
      "1920x1080",
    ],
    isInvalid: false,
    menu1: false,
    menu2: false,
    video_url: "",
    video_width: "",
    video_height: "",
    title: "",
    description: "",
    call_to_action: "",
    brand: "",
    logo_imageurl: "",
    logo_width: "",
    logo_height: "",
    imageurl: "",
    width: "",
    height: "",
  }),

  components: {
    BannerTargetingOptions,
    ShowBanner,
  },
  watch: {
    selectAllPositions(){
      if(this.selectAllPositions){
        this.positions = [
          0,1,2,3,4,5,6,7
        ]
      } else {
        this.positions = []
      }
    },
    all_campaigns() {
      this.campaigns = this.all_campaigns
    },
    selected_restricted_categories() {
      this.restricted_category = this.selected_restricted_categories.name
    },
    async fetched_campaign() {
      this.campaign = this.fetched_campaign
      this.autoPopulateInputFields(this.fetched_campaign)
    },
    banner() {
      this.populateBannerDetails()
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin
    },
    __selectedMatchSize() {
      if (this.match_size === 0) {
        return "any"
      }
      if (this.match_size === 1) {
        return "specified"
      }
      if (this.match_size === 2) {
        return "ranges"
      }
      if (this.match_size === 3) {
        return "list"
      }

      return "specified"
    },
    __selectedBannerType() {
      if (this.banner_type === 0) {
        return "html"
      }
      if (this.banner_type === 1) {
        return "video"
      }
      if (this.banner_type === 2) {
        return "native"
      }
      if (this.banner_type === 3) {
        return "video_banner"
      }

      return "html"
    },

    __appSite() {
      if (this.app_site === 0) {
        return "app"
      }
      if (this.app_site === 1) {
        return "site"
      }
      if (this.app_site === 2) {
        return "both"
      }
      return "both"
    },
  },
  methods: {
    async showBanner() {
      this.bannerShow = !this.bannerShow
      this.preview = {
        name: "Creative Preview",
        imageurl: this.creative_url_link,
        width: this.banner_width,
        height: this.banner_height,
      }
    },
    dismissFn() {
      this.bannerShow = false
    },
    isDisabled() {
      return this.creative_url_link == null ||
        this.banner_height == null ||
        this.banner_width == null
        ? true
        : false
    },
    getCampaign() {
      this.selected_campaign = this.campaign
      this.$emit("getSelectedCampaign", this.selected_campaign.id)
    },

    autoPopulateInputFields(campaign) {
      if (!campaign.campaign_attribute.add_banner_branding) {
        this.add_banner_branding = true
      } else {
        this.add_banner_branding =
          campaign.campaign_attribute.add_banner_branding == 1 ? true : false
      }

      if (campaign.campaign_attribute.cascade_click_url == "1") {
        this.click_through_url = campaign.ad_domain
        this.disabled = true
        this.cascade_status = "Landing page set by campaign"
      } else {
        this.click_through_url = ""
        this.disabled = false
      }

      this.banner_branding_type = campaign.campaign_attribute.branding_type
        ? campaign.campaign_attribute.branding_type.toString().toLowerCase()
        : null
      this.eCpm = campaign.campaign_attribute.bid_ecpm
      this.campaign_id = campaign.id
      this.interval_start = campaign.activate_time.substring(0, 10)
      this.interval_end = campaign.expire_time.substring(0, 10)
    },

    populateBannerDetails() {
      //Only populate banner details //separation of concerns
      this.banner_name = this.banner.name
      this.banner_width = this.banner.width
      this.banner_height = this.banner.height
      this.creative_url_link = this.banner.imageurl
      this.interstitial_status = this.banner.interstitial == 1 ? true : false
      this.add_banner_branding =
        this.banner.banner_attribute.add_banner_branding == 1 ? true : false
      this.banner_branding_type = this.banner.banner_attribute.branding_type
        ? this.banner.banner_attribute.branding_type.toString()
        : null
      this.banner_click_effect = this.banner.banner_attribute.click_effect_type
        ? this.banner.banner_attribute.click_effect_type.toString()
        : ""
      this.eCpm = this.banner.bid_ecpm
      this.click_through_url = this.banner.banner_attribute.click_through_url
        ? this.banner.banner_attribute.click_through_url
        : ""
      this.campaign = this.banner.campaign
      this.campaign_id = this.banner.campaign.id

      //update restricted category
      let backend_restricted_category =
        this.banner.banner_attribute.declared_restricted_categories

      if (backend_restricted_category) {
        this.selected_restricted_categories = this.restricted_categories.find(
          (category) =>
            category.name.toUpperCase() === backend_restricted_category
        )
      } else {
        this.selected_restricted_categories = this.restricted_categories.find(
          (category) => category.name === "Ad has no restricted categories"
        )
      }
      this.interval_start = this.banner.interval_start.substring(0, 10)
      this.interval_end = this.banner.interval_end.substring(0, 10)

      //** Poplulate native fields */
      if (this.banner?.type == "native") {
        this.banner_type = 2
      } else if (this.banner?.type == "video") {
        this.banner_type = 1
      } 
      else if (this.banner?.type == "video_banner") {
        this.banner_type = 3
      }
      else {
        this.banner_type = 0
      }

      //** Populate position, banner.position is a comma string like 1,2,4  */
      if (this.banner?.position) {
        this.positions = this.banner.position.split(",").map(Number)
      }

      this.imageurl = this.banner?.native?.imageurl
      this.width = this.banner?.native?.width
      this.height = this.banner?.native?.height
      this.title = this.banner?.native?.title
      this.description = this.banner?.native?.description
      this.call_to_action = this.banner?.native?.call_to_action
      this.brand = this.banner?.native?.brand
      this.logo_imageurl = this.banner?.native?.logo_imageurl
      this.logo_width = this.banner?.native?.logo_width
      this.logo_height = this.banner?.native?.logo_height
      this.video_url = this.banner?.video?.vast_video_outgoing_file
      this.video_width = this.banner?.video?.vast_video_width
      this.video_height = this.banner?.video?.vast_video_height
    },

    async showSelectedCampaign(value) {
      if (this.$route.query.campaignId) {
        let selected = this.all_campaigns.data.filter(
          (single) => single.id === value
        )

        this.campaign = selected[0]
      }
    },

    campaignBannerType() {
      if (this.add_banner_branding === false) {
        this.banner_branding_type = ""
      }
    },
    setBannerTargetingOptions(selected_banner_targeting_options) {
      this.selected_banner_targeting_options = selected_banner_targeting_options
    },
    setBannerBudget(banner_budget) {
      this.banner_budget = banner_budget
    },
    setDailyBudget(daily_budget) {
      this.daily_budget = daily_budget
    },
    setTotalBudget(total_budget) {
      this.total_budget = total_budget
    },
    setFrequencySpec(frequency_spec) {
      this.frequency_spec = frequency_spec
    },
    setFrequencyExpireAfter(frequency_expire_after) {
      this.frequency_expire_after = frequency_expire_after
    },
    setFrequencyCount(frequency_count) {
      this.frequency_count = frequency_count
    },
    async validate() {
      await this.$refs.form.validate()
      if (!this.valid) return

      const body = {
        //campaign: this.campaign,
        campaign_id: this.campaign_id,
        customer_id: 1,
        name: this.banner_name,
        type: this.__selectedBannerType,
        match_size: this.__selectedMatchSize,
        platform: this.__appSite,
        banner_width_range: this.banner_width_range,
        banner_height_range: this.banner_height_range,
        whlist: this.whlist,
        file: this.banner_file,
        width: this.banner_width,
        height: this.banner_height,
        bid_ecpm: this.eCpm,
        rtb_standards: this.selected_banner_targeting_options,
        total_cost: this.banner_budget,
        daily_budget: this.daily_budget,
        total_budget: this.total_budget,
        frequency_spec: this.frequency_spec,
        frequency_expire: this.frequency_expire_after,
        frequency_count: this.frequency_count,
        interval_start: this.interval_start,
        interval_end: this.interval_end,
        status: false,
        interstitial: this.interstitial_status ? 1 : 0,
        position: this.positions?.length ? this.positions.join(",") : null,
        declared_restricted_categories:
          this.restricted_category === "Ad has no restricted categories"
            ? null
            : this.restricted_category,
        branding_type: this.banner_branding_type,
        add_banner_branding: this.add_banner_branding ? 1 : 0,
        click_effect_type: this.banner_click_effect,
        click_through_url: this.click_through_url,
      }

      if (this.__selectedBannerType === "html" || this.__selectedBannerType === "video_banner") {
        body.imageurl = this.creative_url_link
        body.width = this.banner_width
        body.height = this.banner_height
      }

      if (
        this.__selectedBannerType === "video" ||
        this.__selectedBannerType === "native"
      ) {
        body.video_url = this.video_url
        body.video_width = this.video_width
        body.video_height = this.video_height
      }

      if (this.__selectedBannerType === "native") {
        body.title = this.title
        body.description = this.description
        body.call_to_action = this.call_to_action
        body.brand = this.brand
        body.imageurl = this.imageurl
        body.width = this.width
        body.height = this.height
        body.logo_imageurl = this.logo_imageurl
        body.logo_width = this.logo_width
        body.logo_height = this.logo_height
      }

      // const banner_body = serialize(body)

      if (this.$route.name == "edit-programmatic-banner") {
        this.$emit("emitBannerBodyEdit", body)
      } else {
        this.$emit("emitBannerBodyCreate", body)
      }
    },

    handleFileChange() {
      if (!this.banner_file) {
        return
      }
      if (this.banner_file && this.banner_file.size > 2000 * 1024) {
        alert("File is too big. Please select a file that is 2MB or less.")
        this.banner_file = []
        return
      } else if (
        this.banner_file.length &&
        this.banner_file.type.startsWith("image")
      ) {
        let reader = new FileReader()
        reader.readAsDataURL(this.banner_file)
        reader.onload = (evt) => {
          let img = new Image()
          img.onload = () => {
            let selected_dimensions = this.dimensions.split("x")
            if (
              parseInt(selected_dimensions[0]) !== img.width &&
              parseInt(selected_dimensions[1]) !== img.height
            ) {
              alert(
                "Uploaded Image dimensions do not match selected image dimensions. Please select an image with matching dimensions."
              )
              this.banner_file = []
            }
          }
          img.src = evt.target.result
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.banner_iframe {
  display: block;
  /* iframes are inline by default */
  max-width: 100%;
  border: none;
  /* Remove default border */
}
</style>
